import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  spotonWebsiteHero,
  // spotonWebsiteTrusted,
  spotonWebsiteWhiteGlove,
  spotonWebsiteLargeFeatures,
  // spotonWebsiteVideo,
  spotonWebsiteSolutionsSection,
  websiteSolutionsSlides,
  spotonWebsiteTestimonials,
  // spotonWebsiteIntegrations,
  spotonWebsiteCTA,
  spotonWebsiteFaqs,
  spotonWebsiteSpecial,
} from "../../../data/product-pages/spoton-websites-data";
import { autoBTSlides, heroSuccess } from "../../../data/success-stories-data";
import Hero from "../../../components/Hero/hero";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import Faqs from "../../../components/FAQs/Faqs";
// import WebsiteSpecial from "../../../components/SpecialtyComps/WebsiteSpecial";
import ogImage from "../../../images/global_assets/og-image.png";

const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const Faqs = loadable(() => import("../../../components/FAQs/Faqs"));
const WebsiteSpecial = loadable(() =>
  import("../../../components/SpecialtyComps/WebsiteSpecial")
);

const whiteGloveImport = "website-white-glove.png";

const SpotonWebsites = () => {
  return (
    <Layout>
      <SEO
        title="Ecommerce &amp; Website | Business Website Development"
        description="Increase your online presence with a modern website designed to grow your business, attract new customers, and sell products online. Easily integrate other SpotOn services like Appointments, Online Ordering, and more for a complete experience that will bring you closer to your customers. Best of all, we design it so you don’t have to."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={spotonWebsiteHero} circleBg={false} />
      {/* <TrustedNumbers numbersArray={spotonWebsiteTrusted} /> */}
      <WhiteGlove
        sectionData={spotonWebsiteWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={spotonWebsiteLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <WebsiteSpecial sectionData={spotonWebsiteSpecial} />
      <SuccessStories sectionData={heroSuccess} slides={autoBTSlides} />
      <PartnerLogos />
      {/* <VideoSection sectionData={spotonWebsiteVideo} /> */}
      <ProductLines
        sectionData={spotonWebsiteSolutionsSection}
        productSlides={websiteSolutionsSlides}
      />
      <TestmonialReviews sectionData={spotonWebsiteTestimonials} />
      <PartnerLogos />
      {/* <IntegrationsSection
        sectionData={spotonWebsiteIntegrations}
        integrationList={restaurantData}
      /> */}
      <LargeCta sectionData={spotonWebsiteCTA} />
      <Faqs sectionData={spotonWebsiteFaqs} className="mb-120" />
      {/*      <Articles
        sectionData={restaurantArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default SpotonWebsites;
