// eslint-disable-next-line import/prefer-default-export
export const spotonWebsiteHero = {
  title: "We build your website, you control it",
  subtext:
    "We'll create a complete, professional website for your business, help you maintain it, and provide you with the tools to enhance, personalize, and optimize it, all at an affordable price.",
  heroImg: "header-website.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/website/demo",
  },
  fatTitle: "Website",
};

export const spotonWebsiteTrusted = [
  {
    title: "300m+",
    subtext: "Marketing campaigns",
  },
  {
    title: "5m+",
    subtext: "Loyalty redemptions",
  },
  {
    title: "2,000+",
    subtext: "New businesses each month",
  },
  {
    title: "7+",
    subtext: "Million consumers",
  },
];

export const spotonWebsiteWhiteGlove = {
  mainTitle: "Let us do the work for you",
  title: "We build it for you",
  content:
    "It takes between 10 and 40 hours to build your own website, but when you’re running a business, you don’t have that kind of time. At SpotOn, our experts build the website for you so you can focus on growing your business.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/website/demo",
  },
};

export const spotonWebsiteLargeFeatures = {
  sectionTitle: "A custom site at a do-it-yourself price",
  featureBlocks: [
    {
      blockTitle: "Everything you need in one package",
      blockImg: "website-a.png",
      blockList: [
        "Custom website design with your branding and images",
        "Lightning fast—in 48 hrs or less",
        "Personalized web address",
        "Web hosting",
        "Lifetime support",
        "Self-service dashboard ",
        "Optional online store",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/website/demo",
      },
    },
    {
      blockTitle: "Take control of your website",
      blockSubTitle:
        "Quit struggling to get access to your site from a freelancer, or paying to make a simple update. We’ll build your site fast, then hand over the controls. You can access your website dashboard any time to make quick changes to images, text, colors, and more. Add a DIY touch to your professionally-created website with our expert support whenever you need it.",
      blockImg: "website-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/website/demo",
      },
    },
    {
      blockTitle: "Create new revenue streams",
      blockSubTitle:
        "Our team can seamlessly integrate a variety of SpotOn services into your website, helping you expand your online reach and increase sales.",
      blockImg: "website-c.png",
      blockList: [
        "Ecommerce store to sell your products online",
        "Online appointment booking",
        "Online reservations & waitlisting",
        "Online ordering for take-out & delivery",
        "Loyalty reward status",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/website/demo",
      },
    },
    {
      blockTitle: "Build your brand with custom graphic design",
      blockSubTitle:
        "Uplevel your brand with optional graphic design from our professionals. Whether you want to create more brand awareness with a custom business card, social media graphics, or logo design, we have an option that is right for you at the right price.",
      blockImg: "website-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/website/demo",
      },
    },
    {
      blockTitle: "A website customized to your business needs",
      blockSubTitle:
        "Our team of experts will partner with you to design and create a custom website that delivers on the needs of your business. Need to reach more customers? Want to offer online appointment booking? Looking for a simple solution to host online ordering? Time to start selling online? We’ve got you covered.",
      blockImg: "website-e.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/website/demo",
      },
    },
  ],
};

export const spotonWebsiteBusinessTypesCar = {
  title: "Not Tech-Savvy? No Problem",
  subtext:
    "Just explain your business to us and we'll take it from there. We promise to always speak to you in plain English, not techie-talk.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine dining. Casual dining.",
      cardIcon: "cart.svg",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent: "Repair. Parts.",
      cardIcon: "Wrench.svg",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent: "Hair salons. Nail salons.",
      cardIcon: "Vector.svg",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent: "Accountants. Plumbing.",
      cardIcon: "calendar.svg",
    },
  ],
};

export const spotonWebsiteVideo = {
  title: "See how it works",
  videoBtn: "Watch video",
  posterImg: "spotonWebsite-poster.png",
  videoUrlId: "lIWWySVNAM0",
};

export const spotonWebsiteSolutionsSection = {
  title: "Bring it all together",
  subText: `Take your website productivity further by integrating other SpotOn services.`,
  // SecondaryCta: {
  //   ctaTitle: 'View all',
  //   ctaTarget: '/page-2',
  // },
};

export const spotonWebsiteTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const spotonWebsiteIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept spotonWebsites and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const spotonWebsiteCTA = {
  title: "Sign up for a free demo.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/website/demo",
  },
};

export const spotonWebsiteFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "https://help.spoton.com",
  },
  list: [
    {
      title:
        "I want to use a domain I bought previously from another company. Is this possible?",
      body: "Yes! We can connect your SpotOn website to your existing domain. Just let your specialist know and provide them with access to the registrar where you originally purchased your domain (GoDaddy, Name, Google Domains, etc.). They will assist you in making the connection.",
    },
    {
      title: "Does SpotOn Website provide email address services?",
      body: "We can set up email forwarding at no additional cost if you register a new domain through us.",
    },
    {
      title: "Can you insert software right into the website?",
      body: "You can add links to your website that redirect to third-party software. You can also add supported widgets using the HTML section on the website editor or by providing us an embed code.",
    },
    {
      title:
        "Can SpotOn take over my current website and just make changes to that?",
      body: "Our websites are platform-based. Therefore, while we can make a website that is similar in layout and design to your current site, we are unable to transfer it to our platform. We will build a new site within our guidelines to create a similar design that is professional and showcases your business.",
    },
  ],
};

export const spotonWebsiteSpecial = {
  title: "Websites for any type of business",
  subtext:
    "Our team of experts will create a custom website for you with the right solution for your business to create new sales opportunities.",
  img: "special-website.png",
  bgColor: "#F3F4F6",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/website/demo",
  },
  cardList: [
    "Restaurants, save $7,500/month on average compared to third-party delivery with our integrated online ordering.",
    "Salons, barbershops, and spas, boost sales by 40% or more with integrated appointment booking.",
    "Retailers, sell online with a custom built online storefront with a quick turnaround time-in as little as 48 hours!",
  ],
};

export const websiteSolutionsSlides = [
  {
    title: "Appointments",
    img: "Appointments1.png",
    link: "/products/appointments",
  },
  {
    title: "Register",
    link: "/products/register",
    img: "Register.png",
  },
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Mobile",
    link: "/products/mobile",
    img: "Mobile.png",
  },
  {
    title: "Virtual Terminal",
    link: "/products/virtual-terminal",
    img: "Virtual Terminal.png",
  },
  {
    title: "Online Ordering",
    link: "/products/online-order-and-delivery",
    img: "Online ordering.png",
  },
  {
    title: "Capital",
    link: "/products/capital",
    img: "Capital.png",
  },
  // {
  //   title: 'Payroll',
  //   link: '/products/payroll',
  //   img: 'Payroll.png',
  // },
  {
    title: "Delivery",
    link: "/products/delivery",
    img: "Delivery.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Review Management",
    link: "/products/review-management",
    img: "Review management.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Reserve",
    link: "/products/reserve",
    img: "Reserve.png",
  },
  {
    title: "Website & E-commerce",
    link: "/products/e-commerce",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
  {
    title: "Reporting",
    link: "/products/reporting",
    img: "Reporting.png",
  },
  {
    title: "Restaurant",
    link: "/restaurant-pos",
    img: "Restaurant.png",
  },
];
